<template>
	<div class="workbench">
		<div class="agency">
			<div class="tits">
				<div class="tit">待办消息</div><!-- <span>8</span> -->
			</div>
			<div class="contents">
				<div class="item" @click="$router.push('/member/bulk_order?status=ORDER_ENSURE_CREATE')">
					<div class="num">{{work_bench.todo_margin_pay}}</div>
					<div class="desc">待付定金</div>
				</div>
				<div class="item" @click="$router.push('/member/payment_list')">
					<div class="num">{{ work_bench.todo_pay }}</div>
					<div class="desc">代付货款</div>
				</div>
				<div class="item" @click="$router.push('/member/delivery_order?status='+1)">
					<div class="num">{{work_bench.todo_dlivery }}</div>
					<div class="desc">待收货</div>
				</div>
				<div class="item" @click="$router.push('/member/order_examine')">
					<div class="num">{{work_bench.todo_examine }}</div>
					<div class="desc">待审核订单</div>
				</div>
				<div class="item" @click="$router.push('/member/contract_examine')">
					<div class="num">{{work_bench.todo_sign_examine }}</div>
					<div class="desc">待审核合同</div>
				</div>
				<div class="item" @click="$router.push('/member/contract_list')">
					<div class="num">{{work_bench.todo_sign}}</div>
					<div class="desc">待签订</div>
				</div>
				<div class="item" @click="$router.push('/member/terminate_list')">
					<div class="num">{{work_bench.todo_terminate}}</div>
					<div class="desc">异常订单</div>
				</div>
			</div>
		</div>
		<div class="sale">
			<div class="titles">
				<div class="title">销售概括</div>
				<div class="mode">
					<div class="mode_item" :class="active_mode == 0 ? 'active':''" @click="changeTime(0)">今日</div>
					<div class="mode_item" :class="active_mode == 1 ? 'active':''" @click="changeTime(1)">昨日</div>
					<div class="mode_item" :class="active_mode == 2 ? 'active':''" @click="changeTime(2)">本周</div>
					<div class="mode_item" :class="active_mode == 3 ? 'active':''" @click="changeTime(3)">本月</div>
				</div>
			</div>
			<div class="info">
				<div class="item">
					<div class="top">下单数据统计</div>
					<div class="middle">
						<div class="tit">累计数量<img :src="$img('/upload/web/system-help.png')" alt=""></div>
						<div class="money">{{stat_info.order_pay_count || 0}}</div>
					</div>
				</div>
				<div class="item">
					<div class="top">交易额数据统计</div>
					<div class="middle">
						<div class="tit">累计金额<img :src="$img('/upload/web/system-help.png')" alt=""></div>
						<div class="money">{{stat_info.order_total || 0}}</div>
					</div>
				</div>
				<div class="item">
					<div class="top">付款单数据统计</div>
					<div class="middle">
						<div class="tit">累计数量<img :src="$img('/upload/web/system-help.png')" alt=""></div>
						<div class="money">{{stat_info.bulk_pay_count || 0}}</div>
					</div>
				</div>
				<div class="item">
					<div class="top">收货价值统计</div>
					<div class="middle">
						<div class="tit">累计数量<img :src="$img('/upload/web/system-help.png')" alt=""></div>
						<div class="money">{{stat_info.bulk_shipping_money}}</div>
					</div>
				</div>
				<div class="item">
					<div class="top">发货数量统计</div>
					<div class="middle">
						<div class="tit">累计数量<img :src="$img('/upload/web/system-help.png')" alt=""></div>
						<div class="money">{{stat_info.bulk_shipping_count}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { orderTodo,StatTotal } from '@/api/statistics.js';
	export default {
		data() {
			return {
				work_bench:{},
				start_time:'',
				end_time:'',
				active_mode:0,
				stat_info:{},
			}
		},
		created() {
			if(this.$util.memberStatus() != 2){
				this.$message({
					message: '您还未认证请先进行认证',
					type: 'warning'
				});
				this.$router.push({ name: 'authentication'});
				return;
			}
			this.getWorkBench();
			this.getTime();
		},
		methods: {
			getTime(){
				if(this.active_mode == 0){
					//今天时间戳
					var curTime = parseInt((new Date().setHours(0, 0, 0, 0)) / 1000);
					this.start_time =curTime;
					this.end_time = curTime;
				}else if(this.active_mode == 1){
					//昨日时间戳
					var curTime = parseInt((new Date().setHours(0, 0, 0, 0)) / 1000);
					var startDate = parseInt(curTime - (1 * 3600 * 24));
					this.start_time = startDate;
					this.end_time = startDate;
					console.log(this.start_time,this.end_time,56)
				}else if(this.active_mode == 2){
					var curTime = parseInt((new Date().setHours(0, 0, 0, 0)) / 1000);
					var startDate = parseInt(curTime - ( ( new Date().getDay() - 1 ) * 3600 * 24));
					var endDate = parseInt(curTime + ( ( 7- new Date().getDay() ) * 3600 * 24));
					this.start_time = startDate;
					this.end_time = endDate;
				}else{
					var curTime = parseInt((new Date().setHours(0, 0, 0, 0)) / 1000);
					var startDate = parseInt(curTime - ( ( new Date().getDate() - 1 ) * 3600 * 24));
					var curDate = new Date();
					var curMonth = curDate.getMonth(); //当前月份 需要加1
					curDate.setMonth(curMonth + 1); 
					curDate.setDate(0) //关键
					curDate.getDate(); //计算的当月总天数
					var remain_day = curDate.getDate()-new Date().getDate();
					var endDate = parseInt(curTime + ( remain_day * 3600 * 24));
					this.start_time = startDate;
					this.end_time = endDate;
				}
				this.getStatTotal();
			},
			changeTime(mode){
				this.active_mode = mode;
				this.getTime()
			},
			getStatTotal(){
				StatTotal({
					start_time:this.start_time,
					end_time:this.end_time+86399,
				})
				.then(res=>{
					if(res.code>=0){
						this.stat_info = res.data;
					}
				})
			},
			getWorkBench() {
				orderTodo()
				.then(res=>{
					if(res.code>=0){
						this.work_bench = res.data;
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	.workbench{
		min-height: 730px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.agency{
			width: 100%;
			background-color: #fff;
			margin-bottom: 18px;
			.tits{
				padding: 14px 0 0 0;
				margin-left: 18px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #F1F1F1;
				.tit{
					color: #333;
					font-size: 14px;
					line-height: 20px;
					border-bottom: 2px solid #00A98F;
					margin-right: 8px;
					padding-bottom: 10px;
				}
				span{
					padding: 1px 6px;
					border-radius: 7px;
					margin-bottom: 11px;
					color: #fff;
					font-size: 12px;
					line-height: 12px;
					background-color: #FE4242;
				}
			}
			.contents{
				display: flex;
				align-items: center;
				padding: 32px 32px 31px 18px;
				.item{
					cursor: pointer;
					flex: 1;
					display: flex;
					align-items: center;
					flex-direction: column;
					padding: 14px 0;
					background-color: #F7F7F7;
					margin-right: 24px;
					.num{
						color: #00A98F;
						font-size: 26px;
						line-height: 36px;
						margin-bottom: 2px;
					}
					.desc{
						color: #333;
						font-size: 12px;
						line-height: 16px;
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		.sale{
			width: 100%;
			flex: 1;
			background-color: #fff;
			.titles{
				padding: 14px 0 0 0;
				margin-left: 18px;
				display: flex;
				align-items: flex-start;
				border-bottom: 1px solid #F1F1F1;
				.title{
					padding-bottom: 10px;
					border-bottom: 1px solid #00A98F;
					color: #333;
					font-size: 14px;
					line-height: 20px;
				}
				.mode{
					margin-left: 30px;
					display: flex;
					align-items: center;
					.mode_item{
						cursor: pointer;
						color: #333;
						font-size: 14px;
						line-height: 20px;
						margin-right: 15px;
						&.active{
							color: #00A98F;
						}
					}
				}
			}
			.info{
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				padding: 32px 32px 0 18px;
				.item{
					width: calc( ( 100% - 24px ) / 2 );
					background-color: #F7F7F7;
					padding: 18px 29px 19px 20px;
					margin-right: 24px;
					box-sizing: border-box;
					margin-bottom: 24px;
					.top{
						color: #333;
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 18px;
					}
					.middle{
						display: flex;
						align-items: center;
						flex-direction: column;
						margin-bottom: 24px;
						.tit{
							display: flex;
							align-items: center;
							color: #333;
							font-size: 14px;
							line-height: 20px;
							margin-bottom: 10px;
							img{
								width: 14px;
								height: 14px;
								display: block;
								margin-left: 5px;
							}
						}
						.money{
							color: #00A98F;
							font-size: 26px;
							line-height: 36px;
						}
					}
					.total_data{
						display: flex;
						align-items: center;
						justify-content: space-between;
						div{
							color: #333;
							font-size: 12px;
							line-height: 17px;
						}
					}
					&:nth-child(2n){
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>